import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Spinner from 'react-spinkit';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';

import AuthMailImg from '../assets/images/auth-mail.png';

import { openDeviceLink } from '../utils/redirection-link';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    width: '100vw',
    backgroundColor: theme.palette.white,
    '@media (prefers-color-scheme: dark)': {
      backgroundColor: theme.palette.dark2,
    },
    [theme.breakpoints.down(960)]: {
      paddingTop: 0,
      paddingRight: 68,
      paddingBottom: 0,
      paddingLeft: 68,
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 384,
    marginTop: '-20vh',
    textAlign: 'center',
    [theme.breakpoints.down(960)]: {
      width: '100%',
    },
  },
  imageContainer: {
    marginBottom: 20,
  },
  authenticationCard: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 24,
    paddingRight: 16,
    paddingBottom: 24,
    paddingLeft: 16,
    backgroundColor: theme.palette.white,
    boxShadow: '0px 4px 24px rgba(112, 144, 175, 0.25)',
    borderRadius: 20,
    '& > :not(:last-child)': {
      marginBottom: 12,
    },
    '@media (prefers-color-scheme: dark)': {
      backgroundColor: theme.palette.dark4,
      boxShadow: '0px 4px 4px rgba(112, 144, 176, 0.04)',
    },
  },
  heading: {
    color: theme.palette.blue2,
    fontSize: 24,
    lineHeight: '30px',
    [theme.breakpoints.down(520)]: {
      fontSize: 18,
    },
  },
  message: {
    fontSize: 18,
    lineHeight: '22px',
    color: theme.palette.dark1,
    '@media (prefers-color-scheme: dark)': {
      color: theme.palette.white,
    },
    [theme.breakpoints.down(520)]: {
      fontSize: 16,
    },
  },
  email: {
    display: 'block',
    color: theme.palette.blue2,
    [theme.breakpoints.down(520)]: {
      display: 'inline-block',
    },
  },
  openAva: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 48,
    width: 256,
    paddingTop: 12,
    paddingRight: 24,
    paddingBottom: 12,
    paddingLeft: 24,
    backgroundColor: theme.palette.blue2,
    boxShadow: '0px 4px 4px rgba(112, 144, 176, 0.25)',
    borderRadius: 100,
    color: theme.palette.white,
    fontSize: 18,
    lineHeight: '24px',
    '&:hover': {
      backgroundColor: theme.palette.blue1,
    },
  },
}));

const VerifyEmail = ({ actionCode }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [email, setEmail] = useState('');
  const [validCode, setValidCode] = useState(null);
  const [verifiedCode, setVerifiedCode] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    axios
      .post(`${process.env.REACT_APP_FUNCTIONS_WIDGETS_ENDPOINT}/verifyEmailWithCode`, {
        oobCode: actionCode,
      })
      .then(({ data }) => {
        if (data.emailVerified) {
          setEmail(data.email);
          setValidCode(true);
          setVerifiedCode(true);
        } else {
          setError(data?.message);
          setValidCode(false);
          setVerifiedCode(true);
        }
      })
      .catch((error) => {
        setError(error.message);
        setValidCode(false);
        setVerifiedCode(true);
      });
  }, []);

  let component;
  if (!verifiedCode) {
    component = <Spinner spinnerName="three-bounce" />;
  } else if (verifiedCode && validCode) {
    component = (
      <div className={classes.root}>
        <div className={classes.container}>
          <div className={classes.imageContainer}>
            <img src={AuthMailImg} />
          </div>
          <div className={classes.authenticationCard}>
            <h3 className={classes.heading}>{t('email.verificationSuccessful')}</h3>
            <div className={classes.message}>
              {t('email.signInWithNewAccount')}&nbsp;
              <span className={classes.email}>{email}</span>
            </div>
            <button onClick={() => openDeviceLink()} className={classes.openAva}>
              {t('email.openAva')}
            </button>
          </div>
        </div>
      </div>
    );
  } else if (verifiedCode && !validCode) {
    component = (
      <div className={classes.root}>
        <h1>{t('email.tryVerifyingAgain')}</h1>
      </div>
    );
  }

  return component;
};

export default VerifyEmail;
