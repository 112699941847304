import React,  { useEffect, useState } from 'react';
import { authRef, firebaseRef } from '../reference';

const AuthenticateSAMLSSO = () => {
    const providerId = new URLSearchParams(window.location.search).get("providerId");

    useEffect(() => {
        const signOutAndClear = async () => {
            await authRef.signOut();
            await authRef.setPersistence(firebaseRef.Auth.Persistence.NONE);

            localStorage.clear();
            sessionStorage.clear();
            document.cookie.split(";").forEach(cookie => {
                const name = cookie.split("=")[0].trim();
                document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
            });
        };

        if (providerId) {
            signOutAndClear();
        }
    }, [providerId]);

    if (!providerId) {
        return (
            <div>
                <p>Please append the "providerId" parameter to the URL.</p>
            </div>
        );     
    }
    
    const [user, setUser] = useState(null);
    const [userCredential, setUserCredential] = useState(null);

    const handleSignIn = async () => {
        const provider = new firebaseRef.SAMLAuthProvider(providerId);
        try {
            await authRef.signInWithRedirect(provider);
            const userCredential = await authRef.getRedirectResult();
            setUserCredential(userCredential);
        } catch (error) {
            console.error(error);
        }
    };

    const redirectCallback = (customToken) => {
        const redirectUri = `weareava://callback?customToken=${encodeURIComponent(customToken)}`;
        window.location.href = redirectUri;
    };

    useEffect(() => {
        authRef.onAuthStateChanged(async (user) => {
            setUser(user);

            if (!user) {
                return;
            }

            const idToken = await user.getIdToken();

            try {
                const base64String = btoa(`no-one:${idToken}`);
                const authorization = `Basic ${base64String}`
                const response = await fetch(`${process.env.REACT_APP_SAAS_BACKEND_URL}/api/v1/users/me/customToken`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': authorization,
                    },
                    body: JSON.stringify({
                        idToken,
                    }),
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const result = await response.json();
                const customToken = result.customToken;
                redirectCallback(customToken);
            } catch (error) {
                console.error(error);
            }
        });
    }, []);

    return (
        <div className='Saml-page'>
            {user ? (
                <p>You have signed in!</p>
            ) : (
                <button className='Saml-button' onClick={handleSignIn}>
                 <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <circle cx="16" cy="16.3223" r="16" fill="#FFA500" />
        <g transform="translate(-6, 2) scale(1.3)">
          <path
            d="M17.875 6C15.625 6 13.75 7.875 13.75 10.125C13.75 10.875 13.975 11.55 14.275 12.225L10 16.5V18H13V16.5H14.5V15H16L16.9 14.1C17.2 14.175 17.575 14.25 17.875 14.25C20.125 14.25 22 12.375 22 10.125C22 7.875 20.125 6 17.875 6ZM19 9.75C18.4 9.75 17.875 9.225 17.875 8.625C17.875 8.025 18.4 7.5 19 7.5C19.6 7.5 20.125 8.025 20.125 8.625C20.125 9.225 19.6 9.75 19 9.75Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_1138_128889"
          x="0"
          y="0"
          width="32"
          height="32"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feColorMatrix type="matrix" values="0 0 0 0 0.439216 0 0 0 0 0.564706 0 0 0 0 0.690196 0 0 0 0.25 0" />
          <feComposite in2="hardAlpha" operator="in" result="effect1_dropShadow_1138_128889" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="shape" />
        </filter>
      </defs>
    </svg>
              <h5 className='Saml-text'>Sign in with SAML</h5>
              <div></div>
              </button>
            )}
        </div>
    );
};

export default AuthenticateSAMLSSO;
