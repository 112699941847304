import React from 'react';
import { Route } from 'react-router-dom';

const DevRoute = ({ children, ...rest }) => {
  if (process.env.NODE_ENV !== 'development') {
    window.location.replace('https://ava.me');
    return null;
  }

  return <Route {...rest}>{children}</Route>;
};

export default DevRoute;
