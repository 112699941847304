import React,  { useEffect, useState } from 'react';

const SignOutSamlSso = () => {
    const providerId = new URLSearchParams(window.location.search).get("providerId");
    const nameId = new URLSearchParams(window.location.search).get("nameId");

    if (!providerId || !nameId) {
        return (
            <div>
                <p>Please append the "providerId" and "nameId" parameters to the URL.</p>
            </div>
        );
    }

    const [hasSignedOut, setHasSignedOut] = useState(false);

    const handleSignOut = async () => {
        try {
            const popupWindow = window.open('', 'popupWindow', 'width=600,height=600');

            const url = `${process.env.REACT_APP_FETCH_SAML_LOGOUT_URL_ENDPOINT}?providerId=${providerId}&nameId=${nameId}`;
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({}),
            });

            if (!response.ok) {
                return redirectCallback(false);
            }

            const result = await response.json();
            const logoutUrl = result.logoutUrl;

            if (popupWindow) {
                popupWindow.location.href = logoutUrl;

                setTimeout(() => {
                    popupWindow.close();
                    setHasSignedOut(true);
                    redirectCallback(true);
                }, 2000);
            } else {
                redirectCallback(false);
            }
        } catch (error) {
            console.error(error);
            redirectCallback(false);
        }
    };

    const redirectCallback = (alreadySignedOut) => {
        const redirectUri = `weareava://logout?hasSignedOut=${alreadySignedOut}`;
        window.location.href = redirectUri;
    };

    return (
        <div className='Saml-page'>
            {hasSignedOut ? (
                <p>You have signed out!</p>
            ) : (
                <button className='Saml-button' onClick={handleSignOut}>
                 <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <circle cx="16" cy="16.3223" r="16" fill="#FFA500" />
        <g transform="translate(-6, 2) scale(1.3)">
          <path
            d="M17.875 6C15.625 6 13.75 7.875 13.75 10.125C13.75 10.875 13.975 11.55 14.275 12.225L10 16.5V18H13V16.5H14.5V15H16L16.9 14.1C17.2 14.175 17.575 14.25 17.875 14.25C20.125 14.25 22 12.375 22 10.125C22 7.875 20.125 6 17.875 6ZM19 9.75C18.4 9.75 17.875 9.225 17.875 8.625C17.875 8.025 18.4 7.5 19 7.5C19.6 7.5 20.125 8.025 20.125 8.625C20.125 9.225 19.6 9.75 19 9.75Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_1138_128889"
          x="0"
          y="0"
          width="32"
          height="32"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feColorMatrix type="matrix" values="0 0 0 0 0.439216 0 0 0 0 0.564706 0 0 0 0 0.690196 0 0 0 0.25 0" />
          <feComposite in2="hardAlpha" operator="in" result="effect1_dropShadow_1138_128889" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="shape" />
        </filter>
      </defs>
    </svg>
              <h5 className='Saml-text'>Sign out with SAML</h5>
              <div></div>
              </button>
            )}
        </div>
    );
}

export default SignOutSamlSso;
