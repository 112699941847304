import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route } from 'react-router-dom';

import App from './App';
import SiteTheme from './SiteTheme';

import './i18n';
import './index.css';

ReactDOM.render(
  <SiteTheme>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </SiteTheme>,
  document.getElementById('root')
);
