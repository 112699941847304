import axios from 'axios';
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';

import { authRef } from '../reference';

class Home extends Component {
  state = {
    emailSent: false,
    user: null,
  };

  componentDidMount() {
    const { history } = this.props;

    authRef.onAuthStateChanged((user) => {
      if (!user) {
        history.push('/');
      } else {
        this.setState({ user });
      }
    });
  }

  handleSendEmailVerification = () => {
    axios
      .post(`${process.env.REACT_APP_FUNCTIONS_WIDGETS_ENDPOINT}/sendEmailVerification?uid=${authRef.currentUser.uid}`)
      .then(() => this.setState({ emailSent: true }));
  };

  handleSignOut = () => {
    const { history } = this.props;

    authRef.signOut().then(() => {
      history.push('/');
    });
  };

  render() {
    const { emailSent, user } = this.state;

    const email = user ? user.email : null;
    const emailVerified = user ? user.emailVerified : null;
    const sendEmail = emailSent ? (
      <div className="success">Verification email sent!</div>
    ) : (
      <button onClick={this.handleSendEmailVerification}>SEND VERIFICATION EMAIL</button>
    );

    return (
      <div className="Home">
        <div className="Home-section">Email: {email}</div>

        <div className="Home-section">{emailVerified ? <div>Your email is verified!</div> : sendEmail}</div>

        <div className="Home-section">
          <Link to="/home/update-email">Update Email</Link>
        </div>

        <div className="Home-section">
          <Link to="/home/update-password">Update Password</Link>
        </div>

        <div className="Home-section">
          <button onClick={this.handleSignOut}>SIGN OUT</button>
        </div>
      </div>
    );
  }
}

export default withRouter(Home);
