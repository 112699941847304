import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import Action from './components/Action';
import DevRoute from './components/DevRoute';
import Home from './components/Home';
import Landing from './components/Landing';
import UpdateEmail from './components/UpdateEmail';
import UpdatePassword from './components/UpdatePassword';
import AuthenticateSAMLSSO from './components/AuthenticateSAMLSSO';
import SignOutSamlSso from './components/SignOutSamlSso';

const App = () => {
  return (
    <div className="App">
      <Switch>
        <DevRoute exact path="/">
          <Landing />
        </DevRoute>
        <DevRoute path="/home/update-email">
          <UpdateEmail />
        </DevRoute>
        <DevRoute path="/home/update-password">
          <UpdatePassword />
        </DevRoute>
        <DevRoute path="/home">
          <Home />
        </DevRoute>
        <Route path="/action">
          <Action />
        </Route>
        <Route path="/authenticate-saml-sso">
          <AuthenticateSAMLSSO />   
        </Route>
        <Route path="/sign-out-saml-sso">
          <SignOutSamlSso />
        </Route>
        <Route
          component={() => {
            window.location.href = 'https://ava.me';
            return null;
          }}
        />
      </Switch>
    </div>
  );
};

export default App;
