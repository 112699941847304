import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init(
    {
      resources: {
        en: {
          translation: require('./assets/translation/en.json'),
        },
        fr: {
          translation: require('./assets/translation/fr.json'),
        },
      },
      detection: {
        order: ['cookie', 'localStorage', 'navigator'],
        caches: ['localStorage', 'cookie'],
      },
      fallbackLng: 'en',
      interpolation: {
        escapeValue: false,
      },
    },
    (err, t) => {
      window.document.title = t('title');
    }
  );

export default i18next;
