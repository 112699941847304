import { isAndroid, isIOS } from 'react-device-detect';

const openAndroidLink = () => {
  // TODO: Replace with Android URL
  window.location.replace('https://ava.app.link/download');
};

const openIosLink = () => {
  // TODO: Replace with iOS URL
  window.location.replace('https://ava.app.link/download');

  // Redirect to app store if app was not opened after 2s
  setTimeout(() => {
    // TODO: Replace with the iOS app store URL
    window.location.replace('https://ava.app.link/download');
  }, 2000);
};

const openWebLink = () => {
  window.location.replace('https://web.ava.me');
};

// If user is in mobile device and has the app installed, we try to open the app
// directly. Otherwise, they should be redirected to the app store to download
// the app. Desktop users will be redirected to the webapp.
export const openDeviceLink = () => {
  if (isAndroid) {
    openAndroidLink();
  } else if (isIOS) {
    openIosLink();
  } else {
    openWebLink();
  }
};
