import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import ForgotPassword from './ForgotPassword';
import SignIn from './SignIn';
import SignUp from './SignUp';
import { authRef } from '../reference';

class Landing extends Component {
  componentDidMount() {
    const { history } = this.props;

    authRef.onAuthStateChanged((user) => {
      if (user) {
        history.push('/home');
      }
    });
  }

  render() {
    return (
      <div className="Landing">
        <h1>Sign In</h1>
        <SignIn />

        <h1>Forgot Password</h1>
        <ForgotPassword />

        <h1>Sign Up</h1>
        <SignUp />
      </div>
    );
  }
}

export default withRouter(Landing);
