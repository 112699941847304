import React from 'react';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

const SiteTheme = ({ children }) => {
  const palette = {
    accent: '#FC9C21',
    blue: '#50ACEE',
    blue1: '#0679B4',
    blue2: '#50ACED',
    blue4: '#B0D9F8',
    blue5: '#D0E9FB',
    dark1: '#000000',
    dark2: '#101010',
    dark4: '#232324',
    dark5: '#2D2F34',
    darkBlue: '#214a88',
    darkBg1: 'linear-gradient(131.91deg, #112967 19.06%, #272728 81.95%)',
    darkBg2: 'linear-gradient(229.3deg, #000000 5.57%, #000E34 80.9%)',
    deepBlue: '#1B367C',
    gradientBlue: 'linear-gradient(135deg, #214a88 25%, #438fd0 85%)',
    grey: '#9B9B9B',
    grey4: '#474B43',
    grey5: '#778290',
    grey6: '#DADCE0',
    green: '#34C54F',
    navy: '#102867',
    navy2: '#035A98',
    navy5: '#F4F9FF',
    offBlue1: '#F6F8FF',
    red2: '#DE423E',
    red3: '#E66E6B',
    red4: '#ED9996',
    red5: '#F5C3C2',
    scribe1: '#238736',
    scribe2: '#02C874',
    selfColorBlue: '#035A98',
    selfColorBlueDark: '#155D7C',
    white: '#FFFFFF',
    whiteGradient: 'linear-gradient(131.94deg, #F7FBFF 15.67%, #D5D7D9 85.55%)',
    icon: {
      dark1: '#B0D9F8',
      darkBg: '#5B575E',
    },
    text: {
      dark1: '#F2F2F2',
      dark2: '#DADCE0',
      grey: '#7B7C80',
    },
    text1: '#778290',
    states: {
      online: '#34C54F',
    },
    navyGroup: {
      convoText: '#035A98',
      convoTextDark: '#81C3F3',
    },
  };

  const theme = createTheme({
    palette,
    typography: {
      useNextVariants: true,
      fontFamily: [
        'AdelleSans',
        'Source Sans Pro',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
    },
  });

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  );
};

export default SiteTheme;
